<table mat-table [dataSource]="tableData">

  <ng-container matColumnDef="updatedOn">
    <th mat-header-cell *matHeaderCellDef> Updated On</th>
    <td mat-cell *matCellDef="let element"> {{element.updatedOn | date:'mediumDate'}} </td>
  </ng-container>

  <ng-container matColumnDef="sampleStatus">
    <th mat-header-cell *matHeaderCellDef> Status</th>
    <td mat-cell *matCellDef="let element"> {{element.sampleStatus}} </td>
  </ng-container>

  <ng-container matColumnDef="sampleStatusCode">
    <th mat-header-cell *matHeaderCellDef> Step</th>
    <td mat-cell *matCellDef="let i = index;">
      <app-content-block blockId="adminportal.samplehistory.step"></app-content-block> {{i + 1}}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>

<div class="explanation">
  <app-content-block blockId="adminportal.samplehistory.explanation" [useMarkDown]="true"></app-content-block>
</div>
