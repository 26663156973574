import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {map} from 'rxjs/operators';
import {RequestedModel} from '../om/RequestedModel';
import {UserService} from '../services/user.service';
import {User} from '../om/User';
import {UiService} from '../services/ui.service';
import {SampleHistory, SFDCSample} from '../om/SFDCSample';
import {Breed, Report} from '../om/Report';
import {ContentService} from '../services/content.service';
import {Disorder, Disorders} from '../om/Disorders';
import {MatDialog, MatSnackBar} from '@angular/material';
import {EditPetInfoComponent} from '../edit-pet-info/edit-pet-info.component';
import {environment} from '../../environments/environment';
import {ContactComponent} from '../contact/contact.component';

@Component({
  selector: 'app-admin-portal',
  templateUrl: './admin-portal.component.html',
  styleUrls: ['./admin-portal.component.scss']
})
export class AdminPortalComponent implements OnInit {

  sampleId: string;
  requestedData: RequestedModel;
  requestedSample: SFDCSample;
  relatedSamples: Array<SFDCSample>;
  report: Report;
  ancestry: Array<Breed>;
  disorders: Disorders;
  user: User;
  reportReady: boolean;
  reportFailed: boolean;

  constructor(
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private userService: UserService,
    private ui: UiService,
    private dialog: MatDialog,
    private content: ContentService) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.ui.showLoading();
      combineLatest(this.route.params, this.route.queryParams)
        .pipe(map(results => ({params: results[0], query: results[1]})))
        .subscribe(results => {
          this.sampleId = results.params.sampleId;
          this.requestedData = JSON.parse(atob(results.query.accessToken));
          this.userService.accessToken = this.requestedData.idToken;
          this.loadUser(this.requestedData.userId);
        });
    });
  }

  private showError(err) {
    if (err.status === 403) {
      return this.snackBar.open('Access Denied. Please try again or contact support', 'close');
    }
    return this.snackBar.open(`Could not load sample information: ${err.message}`, 'close', {panelClass: 'error-message-sb'});
  }

  loadUser(userId) {
    this.userService.getUser(userId).subscribe(result => {
      this.user = result;
      this.user.samples.forEach(sample => {
        if (sample.sampleId === this.requestedData.sampleId) {
          this.requestedSample = sample;
        }
      });
      this.ui.hideLoading();
      if (this.requestedSample && this.requestedSample.sampleStatusCode === 5) {
        this.reportReady = true;
        this.loadReport(this.user.userId, this.requestedSample.sampleId);
      } else if (this.requestedSample && this.requestedSample.sampleStatusCode > 5) {
        this.reportFailed = true;
      }

      this.relatedSamples = this.findRelatedSamples(this.user.samples, this.requestedSample.petDetails.banfieldPetId, this.requestedSample.sampleId);
    }, err => {
      console.error(err);
      this.ui.hideLoading();
      return this.showError(err);
    });
  }

  loadReport(userId, sampleId) {
    this.userService.getUserReport(userId, sampleId).subscribe(result => {
      this.report = result;
      this.ancestry = this.content.getBreedPercentages(this.report.sample.ancestry.breeds);
      this.disorders = this.report.sample.health.disorders;
    }, err => {
      console.error(err);
      this.showError(err);
    });
  }

  viewReport() {
    this.ui.showLoading();
    this.userService.getReportUrl(this.user.externalId, this.requestedData.petId).subscribe(result => {
      this.ui.hideLoading();
      if (result.url.indexOf(this.requestedSample.sampleId) < 0) {
        // different sampleId in link, so replace sampleId with correct one - temporary fix until we can reuse accessToken in report
        result.url = result.url.replace(/dnareport\/.*\?channel=/gi, `dnareport/${this.requestedSample.sampleId}?channel=`);
      }
      return window.open(result.url, '_blank');
    }, err => {
      console.log('An error occurred while opening report');
      console.error(err);
      this.ui.hideLoading();
      this.showError(err);
    });
  }

  findRelatedSamples(samples: Array<SFDCSample>, petId: string, currentSampleId: string): Array<SFDCSample> {
    return samples.filter(sample => {
      return sample.petDetails.banfieldPetId === petId && sample.sampleId !== currentSampleId;
    });
  }

  findLatestUpdate(history: Array<SampleHistory>): string {
    let latestDate: string;
    history.forEach(item => {
      if (!latestDate && item.updatedOn) {
        latestDate = item.updatedOn;
      } else if (item.updatedOn && latestDate < item.updatedOn) {
        latestDate = item.updatedOn;
      }
    });
    return latestDate;
  }

  openRelatedSample(sample: SFDCSample): void {
    const params = {
      idToken: this.userService.accessToken,
      petId: sample.petDetails.banfieldPetId,
      sampleId: sample.sampleId,
      clientId: this.user.externalId,
      userId: this.user.userId
    };
    const encoded = btoa(JSON.stringify(params));
    const url = `/${sample.sampleId}?accessToken=${encoded}`;
    window.location.href = url;
  }

  openVetSheet(disorder: Disorder) {
    const url = `${environment.vetsheetUrl}/${disorder.entryId}/en-US`;
    window.open(url, '_blank');
  }

  editPetInfo(user: User, sample: SFDCSample) {
    this.dialog.open(EditPetInfoComponent, {
      width: '600px',
      data: {sample, user}
    });
  }

  showContactDialog() {
    this.dialog.open(ContactComponent);
  }


}
