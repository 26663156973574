<h1 mat-dialog-title>
  <app-content-block blockId="adminportal.contact.title"></app-content-block>
  <button mat-mini-fab [mat-dialog-close] color="primary">
    <mat-icon aria-hidden="false" aria-label="Close Dialog">close</mat-icon>
  </button>
</h1>
<div mat-dialog-content>
  <app-content-block blockId="adminportal.contact.paragraph" [useMarkDown]="true"></app-content-block>
  <app-content-block blockId="adminportal.contact.contactdetails" [useMarkDown]="true"></app-content-block>
</div>

