import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {User} from '../om/User';
import {Report} from '../om/Report';
import {SFDCSample} from '../om/SFDCSample';

const redirectUrl = environment.redirectUrl;
const redirectApiKey = environment.redirectApiKey;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiUrl = environment.apiUrl;
  accessToken: string;

  constructor(public http: HttpClient) {

  }

  private createHeader() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Req-Source': 'BanfieldAdminPortal',
        Authorization: this.accessToken
      })
    };
  }

  getUser(userId) {
    return this.http.get<User>(`${this.apiUrl}/banfield/users/${userId}`, this.createHeader());
  }

  getUserReport(userId, sampleId) {
    return this.http.get<Report>(`${this.apiUrl}/banfield/users/${userId}/samples/${sampleId}/report`, this.createHeader());
  }

  updateUser(user: User) {
    return this.http.put(`${this.apiUrl}/banfield/users/${user.userId}`, user, this.createHeader());
  }

  updateSample(userId, sample: SFDCSample) {
    return this.http.put(`${this.apiUrl}/banfield/users/${userId}/samples/${sample.sampleId}`, sample.petDetails, this.createHeader());
  }

  getReportUrl(clientId, petId) {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': redirectApiKey
      })
    };
    return this.http.get<any>(`${redirectUrl}/${clientId}/${petId}?json=true`, headers);
  }

  // Temporary for testing
  getNewSampleAccess(sample) {
    const authUrl = 'https://r93173km2m.execute-api.us-east-1.amazonaws.com/staging/affiliate/auth';
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': 'sVQ5xT8uG28aP4YADQKdO6wan90UHws96F7CAVCL'
      })
    };
    return this.http.post<any>(`${authUrl}`, sample, headers);
  }
}
