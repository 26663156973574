<div class="header">
  <div class="left-logo">
    <img
      src="https://images.ctfassets.net/ippsyns7liau/2Y8e7036FWcEUWqsYMkyKo/e0a9e8d8c9a097e23d57b46a13d43064/RGB_WisdomPanel_TM.png?h=75"/>
  </div>
  <div class="centered-header">
    <h1>
      <app-content-block blockId="adminportal.header.title"></app-content-block>
    </h1>
    <button mat-raised-button color="accent" *ngIf="user && !(requestedSample.sampleStatusCode > 5)" (click)="viewReport()">
      <app-content-block blockId="adminportal.header.viewreportbutton"></app-content-block>
    </button>
  </div>

  <div class="progress-container" *ngIf="user && requestedSample">
    <app-progress [statusCode]="requestedSample.sampleStatusCode"></app-progress>
  </div>
</div>
<div class="admin-content" *ngIf="user && requestedSample">
  <mat-card>
    <mat-card-title class="title-with-button">
      <app-content-block blockId="adminportal.petinformation.title"></app-content-block>

      <button mat-raised-button color="accent" (click)="editPetInfo(user, requestedSample)">
        <app-content-block blockId="adminportal.petinformation.edit"></app-content-block>
        <mat-icon aria-hidden="false" aria-label="Edit Pet Information">edit</mat-icon>
      </button>

    </mat-card-title>
    <mat-card-content>
      <table class="data-table">
        <tr>
          <td>
            <app-content-block blockId="adminportal.petinformation.petname"></app-content-block>
          </td>
          <td>{{requestedSample.petDetails.name}}</td>
        </tr>
        <tr>
          <td>
            <app-content-block blockId="adminportal.petinformation.petgender"></app-content-block>
          </td>
          <td>{{requestedSample.petDetails.gender | titlecase}}</td>
        </tr>
        <tr>
          <td>
            <app-content-block blockId="adminportal.petinformation.ownername"></app-content-block>
          </td>
          <td>{{user.firstName}} {{user.lastName}}</td>
        </tr>
        <tr>
          <td>
            <app-content-block blockId="adminportal.petinformation.owneremail"></app-content-block>
          </td>
          <td>{{user.email}}</td>
        </tr>
        <tr>
          <td>
            <app-content-block blockId="adminportal.petinformation.hospitalnumber"></app-content-block>
          </td>
          <td>{{requestedSample.clinicCode}}</td>
        </tr>
        <tr>
          <td>
            <app-content-block blockId="adminportal.petinformation.petid"></app-content-block>
          </td>
          <td>{{requestedSample.petDetails.banfieldPetId}}</td>
        </tr>
        <tr>
          <td>
            <app-content-block blockId="adminportal.petinformation.ownerid"></app-content-block>
          </td>
          <td>{{user.externalId}}</td>
        </tr>
      </table>

    </mat-card-content>

    <mat-card-title>
      <app-content-block blockId="adminportal.sampleinformation.title"></app-content-block>
    </mat-card-title>
    <mat-card-content>
      <table class="data-table">
        <tr>
          <td>
            <app-content-block blockId="adminportal.sampleinformation.sampleid"></app-content-block>
          </td>
          <td>{{requestedSample.sampleId}}</td>
        </tr>
        <tr>
          <td>
            <app-content-block blockId="adminportal.sampleinformation.antechnumber"></app-content-block>
          </td>
          <td>{{requestedSample.antechAccessionNo}}</td>
        </tr>
      </table>
    </mat-card-content>

    <mat-card-title>
      <app-content-block blockId="adminportal.samplehistory.title"></app-content-block>
    </mat-card-title>
    <mat-card-content *ngIf="requestedSample">
      <app-sample-history-table [sampleHistory]="requestedSample.sampleStatusHistory"></app-sample-history-table>

      <button mat-raised-button color="accent" (click)="showContactDialog()">
        <app-content-block blockId="adminportal.contact.button"></app-content-block>
      </button>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>
      <app-content-block blockId="adminportal.testhighlights.title"></app-content-block>
    </mat-card-title>
    <mat-card-content *ngIf="reportReady">
      <div *ngIf="disorders">
        <div *ngIf="disorders.clear.length === 0 && disorders.carrier.length === 0 && disorders.atrisk.length === 0">
          <app-content-block blockId="adminportal.testhighlights.nottested"></app-content-block>
        </div>

        <div *ngIf="disorders.clear.length > 0 && disorders.carrier.length === 0 && disorders.atrisk.length === 0 ">
          <app-content-block blockId="adminportal.testhighlights.allclear"
                             [vars]="[{key: 'PET_NAME', value: requestedSample.petDetails.name}, {key: 'amount', value: disorders.clear.length}]"></app-content-block>
        </div>

        <div *ngIf="disorders.clear.length > 0 || disorders.carrier.length > 0 || disorders.atrisk.length > 0">
          <h4>
            <app-content-block blockId="adminportal.testhighlights.atrisk"
                               [vars]="[{key: 'amount', value: disorders.atrisk.length}]"></app-content-block>
          </h4>
          <mat-accordion *ngIf="disorders.atrisk.length > 0 ">
            <mat-expansion-panel *ngFor="let disorder of disorders.atrisk">
              <mat-expansion-panel-header class="right-aligned-header">
                <mat-panel-title>
                  {{disorder.diseaseName}}
                </mat-panel-title>
                <mat-panel-description>
                  {{disorder.diseaseCategory}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <p>{{disorder.whatIsTheDisease}}</p>
              <button mat-button color="primary" (click)="openVetSheet(disorder)">
                <app-content-block blockId="adminportal.testhighlights.readmore"></app-content-block>
                <mat-icon aria-hidden="false" aria-label="Read more PDF">open_in_new</mat-icon>
              </button>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <div *ngIf="disorders.clear.length > 0 || disorders.carrier.length > 0 || disorders.atrisk.length > 0">
          <h4>
            <app-content-block blockId="adminportal.testhighlights.carrier"
                               [vars]="[{key: 'amount', value: disorders.carrier.length}]"></app-content-block>
          </h4>
          <mat-accordion *ngIf="disorders.carrier.length > 0">
            <mat-expansion-panel *ngFor="let disorder of disorders.carrier">
              <mat-expansion-panel-header class="right-aligned-header">
                <mat-panel-title>
                  {{disorder.diseaseName}}
                </mat-panel-title>
                <mat-panel-description>
                  {{disorder.diseaseCategory}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <p>{{disorder.whatIsTheDisease}}</p>
              <button mat-button color="primary" (click)="openVetSheet(disorder)">
                <app-content-block blockId="adminportal.testhighlights.readmore"></app-content-block>
                <mat-icon aria-hidden="false" aria-label="Read more PDF">open_in_new</mat-icon>
              </button>
            </mat-expansion-panel>
          </mat-accordion>

        </div>
      </div>
      <mat-spinner diameter="45" *ngIf="!disorders"></mat-spinner>
    </mat-card-content>

    <mat-card-content *ngIf="!reportReady">
      <app-content-block *ngIf="!reportFailed" blockId="adminportal.testhighlights.nodata"></app-content-block>
      <app-content-block *ngIf="reportFailed" blockId="adminportal.testhighlights.failed"></app-content-block>
    </mat-card-content>

    <!-- <mat-card-title>
      <app-content-block blockId="adminportal.breedlist.title"></app-content-block>
    </mat-card-title>
    <mat-card-content *ngIf="reportReady">
      <mat-list *ngIf="ancestry">
        <mat-list-item *ngFor="let breed of ancestry">
          <h4 mat-line>
            <span class="breed-percentage">{{breed.percentage / 100 | percent:'2.0-2'}}</span> {{breed.displayName}}</h4>
        </mat-list-item>
      </mat-list>
      <mat-spinner diameter="45" *ngIf="!ancestry"></mat-spinner>
    </mat-card-content>
    <mat-card-content *ngIf="!reportReady">
      <app-content-block *ngIf="!reportFailed" blockId="adminportal.breedlist.nodata"></app-content-block>
      <app-content-block *ngIf="reportFailed" blockId="adminportal.breedlist.failed"></app-content-block>
    </mat-card-content> -->

    <mat-card-title>
      <app-content-block blockId="adminportal.relatedsamples.title"></app-content-block>
    </mat-card-title>
    <app-content-block *ngIf="!relatedSamples || relatedSamples.length === 0"
                       blockId="adminportal.relatedsamples.nosamples"></app-content-block>

    <mat-nav-list *ngIf="relatedSamples && relatedSamples.length > 0">
      <mat-list-item *ngFor="let sample of relatedSamples" (click)="openRelatedSample(sample)">
        <h3 mat-line><b>{{sample.sampleId}}</b></h3>
        <p mat-line>{{content.getSampleStatusByCode(sample.sampleStatusCode)}}
          - {{findLatestUpdate(sample.sampleStatusHistory) | date:'mediumDate'}}</p>
      </mat-list-item>
    </mat-nav-list>
  </mat-card>
</div>
