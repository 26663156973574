import {Injectable} from '@angular/core';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {LoadingComponent} from '../loading/loading.component';

interface LoadDialogConfig {
  hasBackdrop?: boolean;
  backdropClass?: string;
}

const DEFAULT_CONFIG: LoadDialogConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
};

@Injectable({
  providedIn: 'root'
})
export class UiService {

  private overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay) {
  }

  showLoading() {
    // Override default configuration
    const dialogConfig = {...DEFAULT_CONFIG};

    // Returns an OverlayRef which is a PortalHost
    this.overlayRef = this.createOverlay(dialogConfig);

    // Create ComponentPortal that can be attached to a PortalHost
    const filePreviewPortal = new ComponentPortal(LoadingComponent);

    // Attach ComponentPortal to PortalHost
    this.overlayRef.attach(filePreviewPortal);
  }

  hideLoading() {
    this.overlayRef.dispose();
  }

  private createOverlay(config: LoadDialogConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config: LoadDialogConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }
}
