<form [formGroup]="petInfoFormGroup">
  <h1 mat-dialog-title>
    <app-content-block blockId="adminportal.editpetinfo.title" *ngIf="data.sample.sampleStatusCode < 6"></app-content-block>
    <app-content-block blockId="adminportal.editownerinfo.title" *ngIf="data.sample.sampleStatusCode == 6"></app-content-block>
    <button mat-mini-fab [mat-dialog-close] color="primary">
      <mat-icon aria-hidden="false" aria-label="Close Dialog">close</mat-icon>
    </button>
  </h1>
  <div mat-dialog-content>
    <mat-form-field *ngIf="!(reportReady || reportFailed)">
      <input matInput type="text" [placeholder]="placeholders.petName" formControlName="petName">
    </mat-form-field>
    <mat-form-field *ngIf="!(reportReady || reportFailed)">
      <mat-label>{{placeholders.petGender}}</mat-label>
      <mat-select formControlName="petGender">
        <mat-option value="male">
          Male
        </mat-option>
        <mat-option value="female">
          Female
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" [placeholder]="placeholders.email" formControlName="email">
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button type="submit" (click)="save()" [disabled]="!petInfoFormGroup.valid || busy" color="accent">
      <app-content-block blockId="adminportal.editpetinfo.save"></app-content-block>
      <mat-spinner diameter="20" *ngIf="busy"></mat-spinner>
    </button>
    <mat-icon aria-hidden="false" 
    aria-label="About" 
    [matTooltip]="toolTipContent"
    >help_outline</mat-icon>
  </div>
  
  
</form>
