import {Component, OnInit} from '@angular/core';
import {UserService} from './services/user.service';
import {UiService} from './services/ui.service';
import {isDevMode} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Banfield Admin Portal';

  // Temporary for easier testing
  sampleList = [
    {
      name: 'Sample with Disorders',
      petId: 'BF0FE570-6F0F-E911-B451-ECEBB8F8A8F1',
      sampleId: '00BAN_5000MARS0001I',
      clientId: 'HOA08GN',
      userId: 'user25@banfield.com'
    },
    {
      name: 'Sample without Disorders tested',
      petId: '50130CAB-8067-E311-A42C-ASDKASJFLAJDFX',
      sampleId: '00BAN_0437MARS0000O',
      clientId: '4BC0UY1',
      userId: 'user25@banfield.com'
    },
    {
      name: 'Sample 1 at risk disorder',
      petId: 'e93d4fde-d476-e611-b8ca-e4115b0dd481',
      sampleId: '00BAN_0169MARS0000U',
      clientId: 'JLG084E',
      userId: 'user25@banfield.com'
    },
    {
      name: 'Sample All clear disorders',
      petId: 'FredNotttt',
      sampleId: '00BAN_1174MARS0002A',
      clientId: 'HOA08GN',
      userId: 'user25@banfield.com'
    },
    {
      name: 'Sample in eDNA Created',
      petId: '50130CAB-8067-E311-A42C-ASDKASJFLAJDFX',
      sampleId: '00BAN_5013MARS000001',
      clientId: 'TR501TX56',
      userId: 'user25@banfield.com'
    },
    {
      name: 'Sample Shipped',
      petId: '516d69eb-facb-e811-842d-00155d9b7c78',
      sampleId: '00BAN_0052MARS0004B',
      clientId: '4BC0UY1',
      userId: 'user25@banfield.com'
    },
    {
      name: 'Failed Sample',
      petId: '555884CAB-8067-E311-A42C-ASDKASJFLAJEF',
      sampleId: '00BAN_2493MARS000X07',
      clientId: 'SLT02FQ',
      userId: 'user25@banfield.com'
    },
  ];

  constructor(private userService: UserService, private ui: UiService) {
    if (!isDevMode()) {
      this.sampleList = [];
    }
  }

  loadSample(sample) {
    this.ui.showLoading();
    this.userService.getNewSampleAccess(sample).subscribe(result => {
      window.location.href = `/${sample.sampleId}?accessToken=${result.accessToken}`;
    });
  }

}
