import {Component, Input, OnInit} from '@angular/core';
import {SampleHistory} from '../om/SFDCSample';
import {ContentService} from '../services/content.service';

@Component({
  selector: 'app-sample-history-table',
  templateUrl: './sample-history-table.component.html',
  styleUrls: ['./sample-history-table.component.scss']
})
export class SampleHistoryTableComponent implements OnInit {

  @Input() sampleHistory: Array<SampleHistory>;
  displayedColumns = ['sampleStatusCode', 'sampleStatus', 'updatedOn'];
  tableData: Array<SampleHistory>;

  constructor(private contentService: ContentService) {
    this.tableData = [
      {sampleStatusCode: 1, updatedOn: null, sampleStatus: this.contentService.getContent('adminportal.header.testordered')},
      {sampleStatusCode: 2, updatedOn: null, sampleStatus: this.contentService.getContent('adminportal.header.samplereceived')},
      {sampleStatusCode: 3, updatedOn: null, sampleStatus: this.contentService.getContent('adminportal.header.dataanalyzed')},
      {sampleStatusCode: 5, updatedOn: null, sampleStatus: this.contentService.getContent('adminportal.header.resultsready')}
    ];
  }

  ngOnInit() {
    if (this.sampleHistory) {
      // Check if sample has failed status. If so remove results ready item and replace with failed
      this.sampleHistory.forEach(sampleHistory => {
        if (sampleHistory.sampleStatusCode === 6) {
          this.tableData.pop();
          this.tableData.push({
            sampleStatusCode: 6,
            updatedOn: null,
            sampleStatus: this.contentService.getContent('adminportal.header.samplefailed')
          });
        }
      });

      this.sampleHistory.forEach(sampleHistory => {
        this.tableData.forEach(row => {
          if (sampleHistory.sampleStatusCode === row.sampleStatusCode && sampleHistory.updatedOn) {
            row.updatedOn = sampleHistory.updatedOn;
          }
        });
      });
    }
  }

}
