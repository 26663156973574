import {Component, Input, OnInit} from '@angular/core';
import {ContentService} from '../services/content.service';
import {ContentVar} from '../om/ContentFul';
import {MarkdownService} from 'ngx-markdown';

@Component({
  selector: 'app-content-block',
  templateUrl: './content-block.component.html',
  styleUrls: ['./content-block.component.scss']
})
export class ContentBlockComponent implements OnInit {

  copy: string;

  constructor(private contentService: ContentService, private markdownService: MarkdownService) {
  }

  @Input() blockId;
  @Input() useMarkDown: boolean;
  @Input() vars: Array<ContentVar> = [];

  ngOnInit() {
    let copy = this.contentService.getContent(this.blockId);
    if (this.vars.length > 0) {
      this.vars.forEach(item => {
        copy = copy.replace(`{{${item.key}}}`, item.value);
      });
    }

    if (this.useMarkDown) {
      copy = this.markdownService.compile(copy);
    }
    copy = copy.replace(/<a /, '<a role="link" tabindex="0" target="_blank" rel="nofollow noopener noreferrer" ');
    this.copy = copy;
  }

}
