<router-outlet></router-outlet>

<div style="padding: 50px" *ngIf="sampleList.length > 0">
  <h2>Other samples to try:</h2>
  <mat-nav-list>
    <mat-list-item *ngFor="let sample of sampleList" (click)="loadSample(sample)">
      <h3 mat-line>{{sample.name}}</h3>
      <p mat-line>{{sample.sampleId}}</p>
    </mat-list-item>
  </mat-nav-list>
</div>
