import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss']
})
export class ProgressComponent implements OnInit {

  @Input() statusCode: number;
  selectedIndex = 1;

  constructor() {
  }

  ngOnInit() {
    this.selectedIndex = 1;
    if (this.statusCode >= 5) {
      this.selectedIndex = 4;
    } else if (this.statusCode >= 3) {
      this.selectedIndex = 3;
    }
  }

}
