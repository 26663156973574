import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatTooltipModule} from '@angular/material';
import {UserService} from '../services/user.service';
import {User} from '../om/User';
import {ContentService} from '../services/content.service';
import {SFDCSample} from '../om/SFDCSample';

interface DialogData {
  sample: SFDCSample;
  user: User;
}

@Component({
  selector: 'app-edit-pet-info',
  templateUrl: './edit-pet-info.component.html',
  styleUrls: ['./edit-pet-info.component.scss']
})
export class EditPetInfoComponent implements OnInit {

  petInfoFormGroup = new FormGroup({
    petName: new FormControl('', [Validators.required]),
    petGender: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  busy: boolean;

  toolTipContent: string; 

  placeholders = {
    petName: '',
    petGender: '',
    email: '',
  };

  reportReady: boolean;
  reportFailed: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<EditPetInfoComponent>,
    private userService: UserService,
    private contentService: ContentService,
    private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.petInfoFormGroup.setValue({
      petName: this.data.sample.petDetails.name,
      petGender: this.data.sample.petDetails.gender.toLowerCase(),
      email: this.data.user.email
    });
    this.placeholders.petName = this.contentService.getContent('adminportal.petinformation.petname');
    this.placeholders.petGender = this.contentService.getContent('adminportal.petinformation.petgender');
    this.placeholders.email = this.contentService.getContent('adminportal.petinformation.owneremail');
    this.toolTipContent = this.contentService.getContent('adminportal.petinformation.tooltip');
    this.reportFailed = this.data.sample.sampleStatusCode == 6 || false;
    this.reportReady = this.data.sample.sampleStatusCode == 5 || false;
  }

  save() {
    this.busy = true;
    
    this.data.sample.petDetails.name = this.petInfoFormGroup.value.petName;
    this.data.sample.petDetails.gender = this.petInfoFormGroup.value.petGender;
    this.userService.updateSample(this.data.user.userId, this.data.sample).subscribe(result => {

      // Only If email address is different from original input, update user
      if (this.petInfoFormGroup.value.email !== this.data.user.email) {
        const user = this.createSaveUser();
        this.userService.updateUser(user).subscribe(userUpdateResult => {
          this.busy = false;
          this.data.user.email = this.petInfoFormGroup.value.email;
          this.dialogRef.close();
          return this.snackBar.open(`Information Updated`, 'close');
        }, err => {
          console.log('Could not update email:');
          console.error(err);
          this.busy = false;
          return this.snackBar.open(`Could not update email address: ${err.error.code}`, 'close');
        });
      } else {
        this.busy = false;
        this.dialogRef.close();
        return this.snackBar.open(`Information Updated`, 'close');
      }
    }, err => {
      console.log('Could not update sample:');
      console.error(err);
      this.busy = false;
      return this.snackBar.open(`Could not save your changes: ${err.error.code}`, 'close');
    });
  }

  private createSaveUser() {
    const user: User = {
      activity: {},
      email: this.petInfoFormGroup.value.email,
      userId: this.data.user.userId,
      externalId: this.data.user.externalId,
      samples: this.data.user.samples,
      billingAddress: {
        address1: this.data.user.billingAddress.address1,
        address2: this.data.user.billingAddress.address1,
        city: this.data.user.billingAddress.city,
        country: this.data.user.billingAddress.country,
        postalCode: this.data.user.billingAddress.postalCode,
        state: this.data.user.billingAddress.state,
      },
      customerId: this.data.user.customerId,
      emailOptOut: this.data.user.emailOptOut,
      firstName: this.data.user.firstName,
      lastName: this.data.user.lastName,
      orders: this.data.user.orders,
      phone: this.data.user.phone,
    };
    return user;
  }
}
