import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AdminPortalComponent} from './admin-portal/admin-portal.component';
import {UserService} from './services/user.service';
import {HttpClientModule} from '@angular/common/http';
import {UiService} from './services/ui.service';
import {LoadingComponent} from './loading/loading.component';
import {ContentService} from './services/content.service';
import {ProgressComponent} from './progress/progress.component';
import {EditPetInfoComponent} from './edit-pet-info/edit-pet-info.component';
import {ReactiveFormsModule} from '@angular/forms';
import {SampleHistoryTableComponent} from './sample-history-table/sample-history-table.component';
import {ContentBlockComponent} from './content-block/content-block.component';
import {MarkdownModule} from 'ngx-markdown';
import {ContactComponent} from './contact/contact.component';
import {AnalyticsService} from './services/analytics.service';

@NgModule({
  declarations: [
    AppComponent,
    AdminPortalComponent,
    LoadingComponent,
    ProgressComponent,
    EditPetInfoComponent,
    SampleHistoryTableComponent,
    ContentBlockComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    MarkdownModule.forRoot(),
  ],
  providers: [
    UserService,
    UiService,
    ContentService,
    AnalyticsService
  ],
  entryComponents: [
    LoadingComponent,
    EditPetInfoComponent,
    ContactComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
