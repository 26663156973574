<mat-horizontal-stepper [linear]="true" #stepper [selectedIndex]="selectedIndex" [class.error]="statusCode > 5">
  <mat-step [editable]="false" [completed]="true" [hasError]="true">
    <ng-template matStepLabel>
      <app-content-block blockId="adminportal.header.testordered"></app-content-block>
    </ng-template>
  </mat-step>
  <mat-step [editable]="false" [completed]="statusCode > 1">
    <ng-template matStepLabel>
      <app-content-block blockId="adminportal.header.samplereceived"></app-content-block>
    </ng-template>
  </mat-step>
  <mat-step [editable]="false" [completed]="statusCode >= 3">
    <ng-template matStepLabel>
      <app-content-block blockId="adminportal.header.dataanalyzed"></app-content-block>
    </ng-template>
  </mat-step>
  <mat-step [editable]="false" [completed]="statusCode === 5" *ngIf="statusCode < 6">
    <ng-template matStepLabel>
      <app-content-block blockId="adminportal.header.resultsready"></app-content-block>
    </ng-template>
  </mat-step>
  <mat-step [editable]="true" [completed]="statusCode > 4" *ngIf="statusCode > 5">
    <ng-template matStepLabel>
      <app-content-block blockId="adminportal.header.samplefailed"></app-content-block>
    </ng-template>
  </mat-step>

  <!-- Workaround for not being able to change icon manually per step  -->
  <ng-template matStepperIcon="edit">
    <mat-icon>close</mat-icon>
  </ng-template>

</mat-horizontal-stepper>
