import {Injectable} from '@angular/core';

import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ContentService} from './content.service';

@Injectable({
  providedIn: 'root'
})
export class ContentResolverService implements Resolve<Observable<boolean>> {

  constructor(private contentService: ContentService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.contentService.initContent();

    // Listen for observable to be finished
    return this.contentService.contentLoaded.asObservable();
  }
}
