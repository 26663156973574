import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AdminPortalComponent} from './admin-portal/admin-portal.component';
import {ContentResolverService} from './services/content-resolver.service';

const routes: Routes = [
  {
    path: ':sampleId',
    component: AdminPortalComponent,
    resolve: { message: ContentResolverService }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
