// This file is for local development. For staging and production the .prod env file will be generated
// based on the env variables set in the application environment

export const environment = {
  production: false,
  vetsheetUrl: 'https://wh-wisdompanel-vetsheet-stage.herokuapp.com/disorder',
  redirectUrl: 'https://services.wisdompanel.com/partnerservices/staging/affiliate',
  redirectApiKey: '7hE1z7PgNS4jLyaXITdCP16ULrmMbZ649bZpjmiw',
  apiUrl: 'https://5ip6ok41n5.execute-api.us-east-1.amazonaws.com/dev/api'
};
