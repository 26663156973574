import {Injectable} from '@angular/core';
import {Breed} from '../om/Report';
import {createClient} from 'contentful';
import {ContentFulPage} from '../om/ContentFul';
import {BehaviorSubject} from 'rxjs';

const CONFIG = {
  space: '2bwr88klmy62',
  accessToken: '7fb0ed797eb9e21cbdd59141664275650a7c62d7da96045d6d2643c590a8795d',
};

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  private mixedBreed = 'Mixed Breed Groups';
  private mixedBreedLocalized = 'Breed Group(s)';
  private content: ContentFulPage;

  private cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken
  });

  contentLoaded = new BehaviorSubject<any>(false);

  constructor() {
  }

  initContent() {
    this.getPage().then(result => {
      if (result.length < 1) {
        throw new Error('Could not load content');
      }
      this.content = result[0].fields as ContentFulPage;
      this.contentLoaded.next(true);
      this.contentLoaded.complete();
    });
  }

  private getPage() {
    const query = {
      content_type: 'page',
      'fields.pageId': 'banfieldAdminPortal'
    };
    return this.cdaClient.getEntries(query)
      .then(res => res.items)
      .catch(err => {
          console.error('Could not load content');
          console.error(err);
          throw new Error('Could not load content');
        }
      );
  }

  getContent(blockId): string {
    const filteredList = this.content.blocks.filter(field => {
      return field.fields.blockId === blockId;
    });
    if (filteredList.length > 0) {
      return filteredList[0].fields.copy;
    }
    console.error(`Could not find block with blockId: ${blockId}`);
    return;
  }

  getBreedPercentages(rawBreedList: Array<Breed>): Array<any> {
    let breedList = [];
    const breedMap = rawBreedList
      .map(({name}) => name)
      .reduce((names, name, index) => {
        let mixedBreed = false;
        if (rawBreedList[index].confFlag === 'X') {
          name = this.mixedBreed;
          mixedBreed = true;
        }
        if (names[name]) {
          const count = names[name].count || 0;
          names[name].count = count + 1;
          names[name].percentage = (names[name].count / rawBreedList.length * 100);
          return names;
        }
        names[name] = {
          breed: mixedBreed ? this.mixedBreedLocalized : rawBreedList[index].name,
          displayName: mixedBreed ? this.mixedBreedLocalized : rawBreedList[index].localized.name,
          count: 1,
          percentage: 12.5,
          localized: rawBreedList[index].localized,
          mixed: mixedBreed
        };
        return names;
      }, {});

    for (const key in breedMap) {
      breedList.push(breedMap[key]);
    }
    breedList = breedList.sort((a, b) => {
      if (a.displayName === this.mixedBreedLocalized) {
        return 1;
      }
      if (b.displayName === this.mixedBreedLocalized) {
        return -1;
      }

      if (a.count === b.count) {
        return (a.displayName < b.displayName) ? -1 : (a.displayName > b.displayName) ? 1 : 0;
      } else {
        return (a.count > b.count) ? -1 : 1;
      }
    });
    return breedList;
  }

  getSampleStatusByCode(code: number): string {
    if (code > 5) {
      return this.getContent('adminportal.header.samplefailed');
    } else if (code === 5) {
      return this.getContent('adminportal.header.resultsready');
    } else if (code >= 3) {
      return this.getContent('adminportal.header.dataanalyzed');
    } else if (code >= 1) {
      return this.getContent('adminportal.header.samplereceived');
    }
    return '';
  }
}
